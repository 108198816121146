import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { categories } from '../assets/options';

const CategoryGrid = ({ data }) => {
  const categoryImages = data.allFile.edges;
  const getCategoryData = (slug) => categories.filter((c) => c.thumbnail === slug)[0];

  return (
    <>
      {categoryImages.map((ci) => {
        const image = getImage(ci.node.childImageSharp);
        const slug = ci.node.name;
        const catData = getCategoryData(slug);

        return (
          <div key={`category-${catData.text}`}>
            <a href={`/${catData.slug}`}>
              <GatsbyImage image={image} alt={catData.text} />
              <h2>{catData.text}</h2>
              <p>{catData.description}</p>
            </a>
          </div>
        );
      })}
    </>
  );
};

export default function IndexWithData(props) {
  return (
    <StaticQuery
      query={graphql`
      query IndexQuery {
        allFile(
          filter: {sourceInstanceName: {eq: "category-images"}}
          sort: {fields: name}
        ) {
          edges {
            node {
              id
              sourceInstanceName
              childImageSharp {
                gatsbyImageData
              }
              name
            }
          }
        }
      }
      `}
      render={(data) => (
        <CategoryGrid
          data={data}
        />
      )}
    />
  );
}
