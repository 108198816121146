import React, {
  useImperativeHandle, useEffect, useRef, forwardRef,
} from 'react';
import Glide from '@glidejs/glide';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { StaticQuery, graphql } from 'gatsby';

import '@glidejs/glide/dist/css/glide.core.css';

const Carousel = forwardRef(({ options, data }, ref) => {
  const posts = data.allMdx.nodes;
  const sliderRef = useRef();

  useImperativeHandle(ref, () => sliderRef.current);

  useEffect(() => {
    const slider = new Glide(sliderRef.current, options);

    slider.mount();

    return () => slider.destroy();
  }, [options]);

  return (
    <div className="glide" ref={sliderRef}>
      <div className="glide__track" data-glide-el="track">
        <ul className="glide__slides">
          {
            posts.map((p) => {
              const image = getImage(p.frontmatter.thumbnail.childImageSharp);
              return (
                <li
                  className="glide__slide"
                  ref={ref}
                  key={`slide-${p.frontmatter.id}`}
                >
                  <GatsbyImage image={image} alt={p.frontmatter.title} />
                  <a href={`/${p.frontmatter.category}/${p.frontmatter.slug}`} alt={p.frontmatter.title}>
                    <div className="title">
                      <span className="title-row">{p.frontmatter.title}</span>
                    </div>
                  </a>
                </li>
              );
            })
          }
        </ul>
      </div>
      <div className="glide__bullets" data-glide-el="controls[nav]">
        {posts.map((p, i) => (<div key={`bullet-${i}`} className="glide__bullet" data-glide-dir={`=${i}`} />))}
      </div>
    </div>
  );
});

export default function CarouselWithData(props) {
  return (
    <StaticQuery
      query={graphql`
      query MyQuery {
        allMdx(
          limit: 5
          sort: {fields: frontmatter___date, order: DESC}
        ) {
          nodes {
            id
            frontmatter {
              title
              category
              slug
              thumbnail {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
      `}
      render={(data) => (
        <Carousel
          data={data}
          options={{
            type: 'carousel',
            autoplay: false,
            gap: 0,
          }}
        />
      )}
    />
  );
}
