import * as React from 'react';
import Head from '../components/Head';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CategoryGrid from '../components/CategoryGrid';
import CarouselWithData from '../components/Slider';

const IndexPage = () => (
  <>
    <Head />
    <Header />
    <main>
      <h1>Die Wall of Fame</h1>
      <p className="tagline">Neues aus dem Stickerstars-Universum</p>
      <div className="full-width">
        <CarouselWithData />
      </div>
      <div className="posts-grid">
        <CategoryGrid />
      </div>
    </main>
    <Footer />
  </>
);

export default IndexPage;
